/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/** Swiper use */
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary)!important;
  --ion-color-base-rgb: var(--ion-color-primary-rgb)!important;
  --ion-color-contrast: var(--ion-color-primary-contrast)!important;
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb)!important;
  --ion-color-shade: var(--ion-color-primary-shade)!important;
  --ion-color-tint: var(--ion-color-primary-tint)!important;
}

ion-icon {
  pointer-events: none;
}

div.swiper-pagination.swiper-pagination-bullets{
  bottom: 4px !important;
}

// CUSTOM (DEFINED BY SIMONE)
ion-button{
  --ion-font-family: 'apercu', sans-serif;
  --ion-font-weight: 200;
  --color: #FFFFFF;
  text-align: center;
  --border-radius: 1.5em;
  text-transform: none;
}
  
ion-fab-button{
  text-align: center;
}

ion-card{
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
}

ion-spinner{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--ion-color-sea);
}


// classe valida per tutti i popover
/*
ion-popover {
  --width: 320px;
}*/

// classe che definisce i popover informativi grandi
ion-popover{
  &.info-popover-big-style-class{
    --max-width: 400px!important;
    --width: 95%!important; 
  }
}

// classe che definisce i soli vendor popover
ion-popover{
  &.vendor-popover-style-class{
    --max-width: 300px!important;
    --width: 95%!important; 
  }
}

// classe che definisce i soli popover animale
ion-popover{
  &.animal-popover-style-class{
    --width:95%;
    --max-width:350px;
  }
}

ion-popover{
  &.signal-popover-class{
    --width:95%;
    --max-width:400px;
  }
}

ion-popover{
  &.book-popover-class{
    --width:95%;
    --max-width:400px;
  }
}

// classe che definisce i popover associazione venditore
ion-popover{
  &.association-popover-style-class{
    --width:95%;
    --max-width:400px;
  }
}

input.searchbar-input.sc-ion-searchbar-md{
  border-radius: 25px;
  font-size: 14px;
}

ion-searchbar{
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
}

div.modal-wrapper.ion-overlay-wrapper.sc-ion-modal-md{
  width: 98%;
  height: 98%; 
  display: block;  
  max-width: 650px;
  max-height: 650px;
}

ion-title.ios.title-large.hydrated{
  font-size: 28px;
}

ion-card-content.ios.card-content-ios.hydrated{
  margin-top: 4px;
}

ion-badge.notifications-badge-ios-buttons{
  background-color: red;
  position: absolute;
	top: -0.1rem;
  right: -0.27rem;
  opacity: 0.9;
  border-radius: 90%;
  height: 18px;
  font-size: 12px;
}

ion-badge.notifications-badge-ios{
  background-color: red;
  position: absolute;
	top: 0.1rem;
  right: -0.07rem;
  opacity: 0.9;
  border-radius: 90%;
  height: 18px;
  font-size: 12px;
}

ion-header.classic{
  background: var(--ion-color-primary);
  background-color: var(--ion-color-primary);
}

// Titoli per IOS
ion-text.ion-color.ion-color-primary.ios.hydrated{
  font-size: 20px;
}

.alert-popup-btn-style{
  color: var(--ion-color-danger) !important;
  font-weight: bold; // Non funziona nel bottone
}

.alert-popup-style {
  .alert-wrapper {
    .alert-head {
      .alert-title{
        color: var(--ion-color-danger) !important;
        font-weight: bold;
      }
    }
  }
}

.alert-info-popup-style {
  .alert-wrapper {
    .alert-head {
      .alert-title{
        color: var(--ion-color-primary) !important;
        font-weight: bold;
      }
    }
  }
}

.alert-info-popup-btn-style{
  color: var(--ion-color-primary) !important;
  font-weight: bold; // Non funziona nel bottone
}

.alert-danger-popup-btn-style{
  color: var(--ion-color-danger) !important;
  font-weight: bold; // Non funziona nel bottone
}

.swiper-pagination-bullet{
  width: 11px;
  height: 11px;
  //--bullet-background: #777777;
  --swiper-pagination-bullet-inactive-color: #777777;  // Migration to Ionic v7
  opacity: 0.8;
}

ion-row.form-row.md.hydrated{
  width: 95%;
  //font-size: 14px; // Ci sarebbero differenze di font-size all'interno delle form
}

// SWIPER styles
swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

/*swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}*/